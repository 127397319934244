.container-web-tracking {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container-divider {
  top: 25px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.container-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 25px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
}

.container-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.container,
.listContainer {
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-gap: 1.5em;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.container {
  padding: 24px 0 0 0;
}

.container::-webkit-scrollbar,
.listContainer::-webkit-scrollbar {
  display: none;
}

.listContainer.spacer,
.listContainer.wideSpacer {
  grid-template-columns: repeat(8, 1fr);
}

.listContainer.spacer,
.listContainer.withSpacer {
  grid-template-columns: repeat(8, 1fr);
}

.container.spacer {
  grid-template-columns: repeat(6, 1fr);
}

.container.wideSpacer {
  grid-template-columns: repeat(6, 1fr);
}

.container.witdSpacer {
  grid-template-columns: repeat(8, 1fr);
}

.gradient {
  background-color: var(--color-background-white);
  background-image: linear-gradient(315deg,
      var(--color-background-white) 0%,
      var(--color-background-white) 74%);
}

.picture {
  cursor: pointer;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading {
  animation: loading-spinner 1s linear infinite;
  margin-top: 0.188rem;
}

@media screen and (max-width: 79.9375em) {
  .container-divider {
    top: 19px;
  }

  .container-line {
    top: 19px;
  }
}